<template>
    <div class="table-waiting table-responsive p-1">
        <table class="table table-striped table-hover text-center">
            <thead>
                <tr>
                    <th colspan="3">{{$t('liste-attente.info-patient')}}</th>
                    <th colspan="3">{{$t('liste-attente.info-rdv')}}</th>
                    <th>{{$t('liste-attente.date')}}</th>
                    <th>{{$t('global.actions')}}</th>
                </tr>
                <tr class="icon-row">
                    <th><i class="fas fa-user"></i></th>
                    <th><i class="fas fa-id-card"></i></th>
                    <th><i class="fas fa-envelope"></i></th>
                    <th><i class="fas fa-user-md"></i></th>
                    <th><i class="fas fa-stethoscope"></i></th>
                    <th><i class="fas fa-calendar-week"></i></th>
                    <th><i class="fas fa-calendar-alt"></i></th>
                    <th><i class="fas fa-ellipsis-h"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="appointment in waitingList">
                    <td>{{ appointment.user.full_name }}</td>
                    <td>{{ appointment.user.matricule ? appointment.user.matricule : '' }}</td>
                    <td class="border-right">{{ appointment.user.email ? appointment.user.email : '' }}</td>
                    <td>{{ appointment.doctor.full_name }}</td>
                    <td>{{ appointment.reason_id ? appointment.reason.label : '' }}</td>
                    <td class="border-right rdv-date">{{ formatDay(appointment.date_debut) }} <br>
                        {{ 'de ' + formatHour(appointment.date_debut) + ' à ' + formatHour(appointment.date_fin) }}
                    </td>
                    <td class="border-right rdv-date">{{ formatDay(appointment.created_at) }} <br>
                        {{ 'à ' + formatHour(appointment.created_at) }}
                    </td>
                    <td>
                        <button class="btn btn-success btn-sm mx-1" @click="onAccept(appointment)" title="Accepter"><i class="fas fa-check"></i></button>
                        <button class="btn btn-primary btn-sm mx-1 my-2" @click="openAppointmentModal(appointment)" title="En savoir plus"><i class="fas fa-info-circle"></i></button>
                        <button class="btn btn-danger btn-sm mx-1" @click="onDelete(appointment)" title="Refuser"><i class="fas fa-times"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { namespaces } from "../../../store/namespaces";
    import moment from 'moment'
    import calendarApi from '../../../api/calendar'
    import AppointmentModal from "./modal/show/AppointmentModal";
    import DeleteAppointmentModal from "./modal/delete/DeleteAppointmentModal";
    import { EventBus } from "@/event-bus";

    export default {
        name: "WaitingListWrapper",
        props: {
            waitingList: {
                required: true,
                type: Array
            },
        },
        data() {
            return {
                form: {
                    pending: 1
                },
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
            }),
        },
        methods: {
            formatDay(date) {
                if (date) {
                    return moment(date).format('DD/MM/YYYY')
                }
            },
            formatHour(date) {
                if (date) {
                    return moment(date).format('HH:mm')
                }
            },
            openAppointmentModal(appointment) {
                this.$modal.show(AppointmentModal,{
                    appointment: appointment
                },{
                    name: 'AppointmentModal',
                    clickToClose: false,
                    adaptive: true,
                    height: 'auto',
                    scrollable: true,
                    transition: 'fade',
                    width:'750px'
                })
            },
            async onAccept(appointment) {
                Vue.$toast.open({
                    message: String(this.$t('liste-attente.acceptation-en-cours')),
                    type: 'info',
                })

                await calendarApi.update({
                    doctor_id: appointment.doctor_id,
                    date_debut: appointment.date_debut,
                    date_fin: appointment.date_fin,
                    pending: 0
                }, appointment.id)

                let res = await calendarApi.getDoctorUser(appointment.doctor_id, appointment.user_id)
                let resDoctorUser = await calendarApi.updateDoctorUser(res.data.doctor_user[0].id, appointment.id)

                Vue.$toast.open({
                    message: String(this.$t('liste-attente.acceptation-en-cours')),
                    type: 'success',
                });

                EventBus.$emit('refresh-waiting-list')
            },
            onDelete(appointment) {
                this.$modal.show(DeleteAppointmentModal,{
                    appointment: appointment
                },{
                    name: 'DeleteAppointmentModal',
                    clickToClose: false,
                    adaptive: true,
                    height: 'auto',
                    scrollable: true,
                    transition: 'fade'
                })
            }
        }
    }
</script>

<style  lang="scss">
.table-waiting {
    table {
        box-shadow: 0 0 0 1px #3c4b64;
        border-style: hidden;
        border-radius: 8px;
    }

    table thead {
        color: #3c4b64;
    }

    table thead th {
        border-color: #3c4b64;
    }

    table thead th, table tbody td {
        vertical-align: middle;
    }

    .icon-row th {
        padding: 5px;
    }

    .icon-row {
        background-color: #3c4b64;
        color: white;
        font-size: 0.8em;
    }

    table tbody {
        font-size: 0.9em;
    }

    .border-right {
        border-right: 1px solid #3c4b64 !important;
    }

    .rdv-date {
        font-size: 0.9em;
    }
}

</style>
